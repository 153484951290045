const isDevelopment = process.env.NODE_ENV === 'development'
const isTrue = (configValue?: string) => configValue === 'true'

export const appConfig = {
    isDevelopment,
    uiKitVisible: isDevelopment || isTrue(process.env.REACT_APP_UI_KIT_VISIBLE),
    createAllToAllBySeller: isDevelopment || isTrue(process.env.REACT_APP_CREATE_ALL_TO_ALL_BY_SELLER),
    enableAnyTimeNewBwic: isDevelopment || isTrue(process.env.REACT_APP_ENABLE_ANY_TIME_NEW_BWIC),
    notificationsDisabled: isTrue(process.env.REACT_APP_NOTIFICATIONS_DISABLED),
    stripeApiKey: process.env.REACT_APP_STRIPE_API_KEY ?? '',
    salesEmail: process.env.REACT_APP_SALES_EMAIL ?? '',
    salesEmailSubscription: process.env.REACT_APP_SALES_EMAIL_SUBSCRIPTION ?? '',
    salesPhone: "+ 1 (800) 862-1684",
    isSummaryRowHidden: isTrue(process.env.REACT_APP_SUMMARY_ROW_HIDDEN),
    canCreateBwicOnUSFederalHolidays: isTrue(process.env.REACT_APP_ALLOW_BWIC_ON_HOLLIDAY)
}
